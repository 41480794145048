import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from '../components/molecules/PageHeader';
import FormWrapper from '../components/organisms/FormView/FormWrapper';
import { buildUrl } from '../helpers/str';
import { urls } from '../services/_identity.service';
import { routes } from './_auth.config';
import { alertError } from '../helpers/alert';

const Login = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const mobile =
        searchParams.get('mobile') || localStorage.getItem('mobile') || '';
    const program_ref_id =
        searchParams.get('program_ref_id') ||
        localStorage.getItem('program_ref_id') ||
        '';
    const [request, setRequest] = useState({ mobile });
    const [config, setConfig] = useState({
        url: buildUrl(urls.send_otp, { silent: true }),
        btn: 'Login',
        items: [
            { name: 'mobile', value: mobile, type: 'number' },
            {
                name: 'program_ref_id',
                type: 'hidden',
                value: program_ref_id,
            },
            {
                name: 'bypass',
                type: 'hidden',
                value: true,
            },
        ],
    });

    const handleSubmit = (response) => {
        return navigate(
            buildUrl(routes.otp, {
                mobile: request.mobile,
                program: response?.program_id,
            }),
        );
    };

    const handleError = (error) => {
        if (error.status_code === 'PROGRAM_ID_REQUIRED') {
            setConfig({
                url: urls.send_otp,
                btn: 'Login',
                items: [
                    { name: 'mobile' },
                    { name: 'program_ref_id', label: 'Program ID' },
                    { type: 'hidden', name: 'bypass', label: true },
                ],
            });
        } else {
            alertError(error.message);
        }
    };

    const handleUpdated = (response) => {
        setRequest(response);
    };

    useEffect(() => {
        const token = searchParams.get('token') || '';
        if(token) {
        localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_AUTH, token);
        navigate(routes.programs);
        }
    }, []);

    return (
        <>
            <PageHeader
                className='p-0 pb-20'
                subtitle='Login using your mobile number to manage your Credit'>
                Sign In
            </PageHeader>
            <FormWrapper
                type='form'
                config={config}
                updated={handleUpdated}
                submitted={handleSubmit}
                onError={handleError}
                data={request}
            />
        </>
    );
};

export default Login;
